import React from "react";

export default function SingleBlog() {
  return (
    <>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <article className="post">
                <div className="post-preview">
                  <img
                    src="assets/img/blog/1-w.jpg"
                    alt="article"
                    className="img-fluid"
                  />
                </div>
                <div className="post-wrapper">
                  <div className="post-header">
                    <h1 className="post-title">Effective Date</h1>
                    <ul className="post-meta">
                      <li>March 28, 2023</li>
                    </ul>
                  </div>
                  <div className="post-content">
                    <p>
                      This Privacy Policy describes how Sous Chef ("we" or "us")
                      collects, uses, and shares information about you when you
                      use our mobile application ("App") and website ("Site")
                      located at https://souschef.dev (collectively, the
                      "Services"). By using the Services, you agree to the
                      collection and use of your information in accordance with
                      this Privacy Policy.
                    </p>
                    <h5>Information We Collect</h5>
                    <p>
                      We collect information you provide to us directly, such as
                      when you create an account, update your profile, or use
                      certain features of the App. This information may include
                      your name, email address, phone number, and payment
                      information.
                    </p>
                    <p>
                      We also collect information about your use of the
                      Services, including your interactions with the App and
                      Site, the type of device you are using, your IP address,
                      and other diagnostic information. This information may be
                      collected through cookies, web beacons, and similar
                      technologies.
                    </p>
                    <h5>How We Use Your Information</h5>
                    <p>
                      We use your information to provide, maintain, and improve
                      the Services, as well as to personalize your experience.
                      For example, we may use your information to:
                    </p>
                    <ul>
                      <li>Create and manage your account</li>
                      <li>Provide customer support</li>
                      <li>Improve our products and services</li>
                      <li>Understand how you use the Services</li>
                      <li>
                        Send you promotional emails and other marketing
                        communications
                      </li>
                      <li>
                        Respond to legal requests or prevent illegal activities
                      </li>
                      <li>Enforce our policies and terms of service</li>
                    </ul>
                    <h5>How We Share Your Information</h5>
                    <p>
                      We may share your information with third-party service
                      providers who help us provide the Services, such as
                      payment processors, hosting providers, and customer
                      support providers. We may also share your information with
                      our business partners and other third-party vendors to
                      promote their products and services.
                    </p>
                    <p>
                      We may disclose your information to law enforcement
                      authorities or other third parties if we believe that such
                      disclosure is necessary to comply with the law or to
                      protect our rights or the rights of others. We may also
                      disclose your information in connection with a merger,
                      acquisition, or sale of all or a portion of our assets.
                    </p>
                    <h5>Your choices</h5>
                    <p>
                      You can control the information we collect about you by
                      adjusting your device or browser settings to block or
                      delete cookies and other similar technologies. You can
                      also unsubscribe from our marketing communications at any
                      time by clicking the "unsubscribe" link at the bottom of
                      our emails.
                    </p>
                    <p>
                      If you live in certain jurisdictions, such as the European
                      Union, you may have additional rights regarding the
                      information we collect about you. These rights may include
                      the right to access, correct, or delete your information,
                      as well as the right to object to or restrict certain
                      types of processing.
                    </p>
                    <h5>Security</h5>
                    <p>
                      We take reasonable measures to protect your information
                      from unauthorized access, use, and disclosure. However, no
                      method of transmission over the internet or method of
                      electronic storage is completely secure, so we cannot
                      guarantee absolute security.
                    </p>
                    <h5>Changes to this policy</h5>
                    <p>
                      We may update this Privacy Policy from time to time by
                      posting the updated policy on our Site. Your continued use
                      of the Services after the effective date of any changes to
                      this policy constitutes your acceptance of the updated
                      policy.
                    </p>
                    <h5>Contact us</h5>
                    <p>
                      If you have any questions about this Privacy Policy,
                      please contact us at{" "}
                      <a href="mailto:privacy@souschef.dev">
                        privacy@souschef.dev
                      </a>
                    </p>
                  </div>
                  <div className="post-footer">
                    <div className="post-tags">
                      <a href="#/">Privacy</a>
                      <a href="#/">Policy</a>
                      <a href="#/">Security</a>
                      <a href="#/">Sous Chef</a>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
